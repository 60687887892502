<template>
  <ion-content class="ion-padding">
    <div>
      <center><ion-title size="large">Reclasin</ion-title></center>  
      <br/>
        <div>
          <ion-row>
            <ion-col  sizeLg="4" sizeMd="4" sizeXs="12"></ion-col>
            <ion-col  sizeLg="4" sizeMd="4" sizeXs="12">
              <ion-input type="text"  v-model="email" placeholder="Ingrese el usuario o el correo electrónico" />
              <ion-input type="password" v-model="password" placeholder="Ingrese la contraseña" />
              
              <center>
                <ion-button color="dark" @click="login()">
                  Enviar
                </ion-button>
                <br>
                <ion-button color="dark" @click="redirect()">
                  Registro
                </ion-button>
              </center>
              <center> <p><b>o</b></p></center>
              <div style="justify-content: center;display: flex;">
                <img src="/assets/icon/icon-facebook.png" @click="loginFacebook" >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src="/assets/icon/icon-google.png" @click="loginGoogle" >
              </div>
              </ion-col>
            <ion-col  sizeLg="4" sizeMd="4" sizeXs="12"></ion-col>
          </ion-row>
        </div>
      </div>
  </ion-content>
</template>

<script>
import { IonButton, IonInput } from "@ionic/vue";
import axios from 'axios'
import jwtToken from "@/plugins/jwt/jwt-token";
import {mapActions} from "vuex";
import user from "@/plugins/jwt/user";
import  '@capacitor-community/facebook-login';
import  '@codetrix-studio/capacitor-google-auth';
import { Plugins } from '@capacitor/core'
import toast from '@/plugins/toast'
//import Form from "../components/Form";
//import People from "../components/People";
//import PayCardOverview from "../components/PayCardOverview";

export default {
  name: "pay",
  title: "Pay",
  requiresAuth: false,
  components: {
    //IonPage,
    IonInput,
    //Form,
    //People,
    //PayCardOverview,
    IonButton
  },
  data() {
    return {
      email : '',
      password : ''
    };
  },
  mounted(){
    Plugins.GoogleAuth.initialize()
   
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '403919611251185',
        cookie: true, // enable cookies to allow the server to access the session
        xfbml: true, // parse social plugins on this page
        version: 'v12.0' // use graph api current version
      });
    };

    // Load the SDK asynchronously
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },
  methods: {
    ...mapActions([
          'setAuthUser',
    ]),
    async login(){

      var loading = await toast.showLoading()

      loading.present();

      let data = {
        email : this.email,
        password : this.password
      }

      axios.post('/api/auth/login',data)
      .then(res =>{
        loading.dismiss()
        console.log(res)
        user.setUser(res.data.user)
        jwtToken.setToken(res.data.token);
        this.setAuthUser(res.data.user)
        console.log(res.data.user.role.name)
        
        if (res.data.user.role.name == 'Despacho') {
          location.href = '/escritorio'
        }else{
           location.href = res.data.user.permissions[0].key
        }
        
       
      }).catch(error => {
        loading.dismiss()
        if (error.response.status == 400) {
          toast.openToast("Creendenciales Invalidas","error",10000);
        }
        
        console.log(error)
      })
    },
    redirect(){
      location.href = "/register"
    },
    async loginFacebook(){


      const FACEBOOK_PERMISSIONS = ['email'];
      const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });

      if (result.accessToken) {
        this.token = result.accessToken;
      }else{
        toast.openToast("Error al registrar con facebook","error",2000);
        return
      }
      
      //alert(JSON.stringify(result))
      var loading = await toast.showLoading()

      await loading.present();

      const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),email&access_token=${this.token.token}`;
    
      axios
      .get(url)
      .then(res => {
        this.fb_user = res.data
        
        axios
        .post("/auth/mobile/external",{email : this.fb_user.email , names :this.fb_user.name, provider : 'facebook',key : 'google'  })
        .then(async res => {
          loading.dismiss()
          user.setUser(res.data.user)
          jwtToken.setToken(res.data.token);
          this.setAuthUser(res.data.user)
          await Plugins.FacebookLogin.logout()
          this.$router.push({path: '/dashboard' , query : {set_fcm : true }});
        })
        .catch(err => {
          loading.dismiss()
          console.log(err.response)
          /*if(err.response.data?.message){
            toast.openToast(err.response.data.message,"error",2000);
          }else{
            toast.openToast("Ha ocurrido un error","error",2000);
          }*/
       }) 
      
      })
      .catch(err => {
        console.log(err)
        loading.dismiss()
        //toast.openToast("Ha ocurrido un error","error",2000);
      }) 
   },
    async loginGoogle() {
    
    const googleUser = await Plugins.GoogleAuth.signIn()
    
    console.log('my user: ', googleUser);
    
    if(!googleUser?.email){
      toast.openToast("Error al autenticar con google","error",2000);
      return
    }
    
    var loading = await toast.showLoading()

    await loading.present();

    let data = {
       email : googleUser.email,
       names : googleUser.name,
       provider : 'google',
       key : 'google' 
    }

    axios
      .post("/auth/mobile/external",data)
      .then(async res =>  {
        loading.dismiss()
        user.setUser(res.data.user)
        jwtToken.setToken(res.data.token);
        this.setAuthUser(res.data.user)
        await Plugins.GoogleAuth.signOut();
        this.$router.push({path: '/dashboard' , query : {set_fcm : true }});
      })
      .catch(err => {
        loading.dismiss()
        console.log(err.response)
        /*if(err.response.data?.message){
          toast.openToast(err.response.data.message,"error",2000);
        }else{
          toast.openToast("Ha ocurrido un error","error",2000);
        }*/
      });
    }
  }
};
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: 200px;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>



